@font-face {
  font-family: "NeueHaasGrotesk";
  src: local("NeueHaasGrotesk"),
  url(./fonts/NeueHaasGrotesk.woff) format("woff");
}

@font-face {
  font-family: "OcraStd";
  src: local("ocrastd"), url(./fonts/ocrastd.otf) format("opentype");
}

@font-face {
  font-family: 'Roboto Flex';
  src: url('./fonts/RobotoFlex-VariableFont_GRAD,XOPQ,XTRA,YOPQ,YTAS,YTDE,YTFI,YTLC,YTUC,opsz,slnt,wdth,wght.ttf') format('truetype');
  font-weight: 300 900;
  font-stretch: 50% 100%; 
  font-style: normal; 
}

@font-face {
  font-family: 'Roboto Mono';
  src: url('./fonts/RobotoMono-VariableFont_wght.ttf') format('truetype');
  font-weight: 300 900;
  font-stretch: 50% 100%; 
  font-style: normal; 
}

@font-face {
  font-family: 'Figtree';
  src: url('./fonts/Figtree-VariableFont_wght.ttf') format('truetype');
  font-weight: 300 900;
  font-stretch: 50% 100%; 
  font-style: normal; 
}

@font-face {
  font-family: 'Sofia Sans Condensed';
  src: url('./fonts/SofiaSansCondensed-VariableFont_wght.ttf') format('truetype');
  font-weight: 300 900;
  font-stretch: 50% 100%; 
  font-style: normal; 
}

@font-face {
  font-family: 'Sofia Sans Extra Condensed';
  src: url('./fonts/SofiaSansExtraCondensed-VariableFont_wght.ttf') format('truetype');
  font-weight: 300 900;
  font-stretch: 50% 100%; 
  font-style: normal; 
}

@font-face {
  font-family: 'Montserrat';
  src: url('./fonts/Montserrat-VariableFont_wght.ttf') format('truetype');
  font-weight: 300 900;
  font-stretch: 50% 100%; 
  font-style: normal; 
}

body {
  margin: 0;
  font-family: Termina, "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: black;
  background: #340B7C;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
  monospace;
}

h1 {
  font-family: Termina;
  font-weight: 600;
  font-size: 70px;
  color: black;
  margin: 0;
  line-height: 1.35;
  transform: translateX(-5px);
}

h2 {
  font-family: Termina;
  font-weight: 500;
  font-size: 27px;
  color: black;
  margin: 25px 0;
}

h3 {
  font-family: Termina;
  font-weight: 500;
  font-size: 20px;
  color: black;
  margin: 25px 0;
}

h5 {
  font-family: Termina;
  font-weight: bold;
  font-size: 20px;
  color: white;
  margin: 0;
  line-height: 1.1;
}

ul {
  list-style-type: square;
}

li {
  margin-bottom: 20px;
  padding-left: 8px;
  text-align: left;
}

p {
  margin: 10px;
}

td {
  vertical-align: top;
}

#root {
  position: relative;
}

.mobileMenu {
  display: none;

}

.desktopMenu {
  display: block;
}

.container {
  max-width: 1440px;
  position: relative;
  margin: 0 auto;
}

.font-Figtree {
  font-family: Figtree, sans-serif;
}

.menu {
  float: right;
}

.menuitem:last-child {
  margin-right: 0 !important;
}

.hamburger {
  display: none;
}

.menu-mobile {
  display: none;
}

.mobile-show {
  display: none;
}

.section {
  display: flex;
  justify-content: center;
  align-items: stretch;
  z-index: 1;
  margin: 0 110px;
}

.section-full {
  padding-top: 200px;
  z-index: 1;
}

.section-text {
  max-width: 44%;
}

.section-graphics {
  position: relative;
  display: flex;
  align-items: center;
  width: 44%;
  z-index: 3;
}

.section-graphics img {
  position: absolute;
  height: 110%;
  max-height: 1000px;
}

.font-black {
  color: #000000;
}

.input {
  font-family: Termina;
  color: black;
  padding: 10px 14px;
  font-size: 18px;
  font-weight: 600;
  height: 60px;
  margin: 15px;
  text-align: center;
  outline: none;
  border: none;
  border-radius: 10px;
  background: #00000011;
  backdrop-filter: blur(10px);
}

.input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: black;
  opacity: 0.7; /* Firefox */
}

.custom-file-upload {
  font-family: Termina;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600 !important;
  height: 60px;
  width: 80%;
  background: #c1ef2c;
  margin: 15px;
  padding: 10px 14px;
  border-radius: 10px;
  cursor: pointer;
  font-size: 18px;
  color: black;
}

.team-member-img:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(169, 18, 234, 0.1);
  transition: all 0.3s linear;
}

.image-slider::-webkit-scrollbar {
  display: none;
}

.image-slider {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.footer {
  height: 150px;
}

.pressComps {
  display: flex;
  width: 95%;
  justify-content: center;
}

.pressComp {
  display: flex;
  flex-direction: column;
  width: 39%;
}

.newsimg {
  max-width: 96%;
  // margin-top: 20px;
  border-radius: 6px;
}

.newstext {
  margin-left: 0;
}

.paragraph {
  width: 100%;
  text-align: left;
  margin: 0 auto;
}

@media only screen and (max-width: 900px) {
  .news-text{
    p {
      text-align: justify;
    }
  }
}

.font-neue-haas {
  font-family: "NeueHaasGrotesk", Courier, sans-serif;

  p {
    font-family: 'NeueHaasGrotesk', sans-serif;
  }
}

.text-align-left {
  text-align: left;
}

.text-align-justify {
  text-align: justify;
}

.heading-one {
  font-size: 33px;
  text-align: left;
  margin-left: 0;
  font-weight: 600;
}

.ingress {
  width: 900px;
  max-width: 90%;
  text-align: center;
  margin: 0 auto;
}

@media only screen and (max-width: 900px) {
  h1 {
    font-size: 8vw !important;
    text-align: center;
    line-height: 1.2;
    margin: 4px 0;
    max-width: 100%;
    transform: none;
  }

  h2 {
    font-size: 18px !important;
    text-align: center;
    line-height: 1.2;
  }

  body {
    font-size: 12pt !important;
    text-align: justify;
    max-width: 100% !important;
  }

  .mobileMenu {
    display: block;
  }

  .desktopMenu {
    display: none;
  }

  .menu {
    display: none;
  }

  .hamburger {
    display: inline-block;
  }

  .ring {
    display: none;
  }

  .menu-mobile {
    display: block;
  }

  .mobile-show {
    display: block;
  }

  .menuitem {
    margin: 10px !important;
  }

  .menuitem:last-child {
    margin-right: 10px !important;
  }

  .section {
    margin: 0 20px !important;
    flex-direction: column;
    padding-top: 50px !important;
  }

  .image-slider {
    flex-direction: column;
  }

  .section-text {
    order: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 100%;
    margin-top: -30px;
    text-align: center;
  }

  .section-full {
    margin: 0 20px !important;
    padding-top: 90px !important;
  }

  .section-graphics {
    order: 1;
    width: 100% !important;
    justify-content: center;
  }

  .section-graphics img {
    position: relative;
    width: 80%;
    height: auto !important;
    left: 0 !important;
    right: 0 !important;
  }

  .section-first {
    order: 1;
    display: block;
    text-align: center !important;
    width: 100% !important;
    max-width: 100% !important;
    opacity: 1 !important;
  }

  .section-second {
    order: 2;
    display: block;
    text-align: justify;
    width: 100% !important;
    max-width: 100% !important;
    margin: 30px 0;
    justify-content: center;
    opacity: 1 !important;
  }

  .section-third {
    order: 3;
    display: block;
    text-align: justify;
    width: 100% !important;
    max-width: 100% !important;
    margin: 30px 0;
    justify-content: center;
    opacity: 1 !important;
  }

  .investors {
    margin: 0 20px !important;
  }

  .investors-list {
    flex-direction: column;
    align-items: center;
  }

  .investor-img {
    height: 180px !important;
  }

  .rowbreak {
    display: none;
  }

  .cultureValues {
    height: 300px !important;
    margin-top: 150px !important;
  }

  .valuesHeader {
    white-space: normal !important;
  }

  .valuesText {
    font-size: 14pt;
  }

  .jobDesc {
    float: none !important;
    margin-left: unset !important;
    width: unset !important;
    margin-bottom: 50px;
  }

  .jobHeader {
    margin: unset !important;
  }

  .jobSubheaderHolder {
    display: flex;
    justify-content: center;
  }

  .jobSubheader {
    justify-content: center;
    margin-top: 40px;
    padding-left: 0 !important;
  }

  .jobSubheader h2 {
    max-width: 60%;
  }

  .jobSubheader img {
    margin-right: 10px !important;
    left: -30px !important;
  }

  .jobText {
    margin: 15px 15px !important;
    text-align: center;
    font-size: 13pt;
  }

  .jobBullet {
    margin-left: unset !important;
    padding-right: unset !important;
  }

  .jobBullet ul {
    padding-left: 33px;
  }

  .jobBullet h3 {
    font-size: 13pt;
    text-align: left;
    margin: 0 20px 0 0 !important;
  }

  .benefits {
    position: relative !important;
    right: unset !important;
    width: unset !important;
    border-radius: unset !important;
    padding: 20px 0 !important;
    margin-bottom: -170px;
  }

  .form {
    width: 90% !important;
  }

  .formRow {
    flex-direction: column;
  }

  .formRow label {
    float: none !important;
  }

  .input {
    width: unset !important;
  }

  .footer {
    flex-direction: column;
    height: auto;
  }

  .footer-buttons {
    display: inline !important;
  }

  .img-rotated {
    transform: rotate(0) !important;
  }

  .pressComps {
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
  }

  .pressComp {
    width: 90%;
  }

  .newstext {
    text-align: left;
  }

  .jobcard img {
    left: 70px !important;
  }

  .jobcardtitle {
    width: 60% !important;
  }
}


.slick-prev:before, .slick-next:before {
  display: none;
}

.slick-dots li button:before {
  content: '';
  border: 1px solid #F5F5F5; 
  width: 8px !important;
  height: 8px !important;
  border-radius: 50%;
  opacity: 1 !important; 
  font-size: 0 !important;
}

.slick-dots li.slick-active button::before {
  background-color: #F5F5F5;
}

.heading-three {
  margin-inline: 10px;
  margin-top: 56px;
  margin-bottom: 0;
  font-weight: 700;
  margin-left: 0;
}

.heading-four {
  margin-inline: 10px;
  margin-top: 31px;
  margin-bottom: 0;
  font-weight: 700;
  margin-left: 0;
}

.empty-paragraph {
  height: 26px;
}

.text-white {
  color: #ffffff;
}

body{
  // make scroll smooth
    scroll-behavior: smooth;
}